import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9fb6d3be&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=9fb6d3be&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsEditoImageV2: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/edito-image-v2/edito-image-v2.vue').default})
